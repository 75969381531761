// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as Routes_ResourceCategory from "../../../routes/common/Routes_ResourceCategory.res.js";

function ResourceDashboardNavbar(props) {
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex([
                          {
                            id: "Categories",
                            slug: Routes_ResourceCategory.dashboard
                          },
                          {
                            id: "Resources",
                            slug: Routes_Resource.Dashboard.index
                          },
                          {
                            id: "Analytics",
                            slug: Routes_Resource.Dashboard.analytics
                          }
                        ], (function (index, category) {
                            var match = category.id;
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: category.slug,
                                              className: category.id === selectedCategory ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem,
                                              children: match === "Categories" ? "Resource Categories" : (
                                                  match === "Analytics" ? "Analytics" : "Resources"
                                                )
                                            }),
                                        className: Cx.cx([category.id === selectedCategory ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer])
                                      }, String(index));
                          })),
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = ResourceDashboardNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
